<ion-header class="templates-header">
    <ion-toolbar style="text-align: center;">
      <ion-title class="templates-header-title">Your Bank Info</ion-title>
      <ion-buttons slot="end">
        <ion-button (click)="closeModal()">
          <ion-icon name="close"></ion-icon>
        </ion-button>
      </ion-buttons>
    </ion-toolbar>
  </ion-header>
  
  <ion-content>
    <div class="wrap-field">
      <p>Name</p>
      <ion-input type="text" [(ngModel)]="bankDetails.name"></ion-input>
    </div>
  
    <div class="wrap-field">
      <p>Bank Account Number</p>
      <ion-input type="number" [(ngModel)]="bankDetails.accountNumber"></ion-input>
    </div>
  
    <div class="wrap-field">
      <p>Phone Number</p>
      <ion-input type="number" [(ngModel)]="bankDetails.phoneNumber"></ion-input>
    </div>
  
    <div class="wrap-field">
      <p>IFSC Code</p>
      <ion-input type="text" [(ngModel)]="bankDetails.ifscCode"></ion-input>
    </div>
  
    <div class="wrap-field">
      <p>UPI ID</p>
      <ion-input type="text" [(ngModel)]="bankDetails.upiId"></ion-input>
    </div>
  
    <div style="text-align: center; padding: 20px;">
      <ion-button (click)="saveBankDetails()">Save</ion-button>
    </div>
  </ion-content>