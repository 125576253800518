import { Component, OnInit } from '@angular/core';
import { LoadingController, ModalController } from '@ionic/angular';
import { LabelService } from 'src/app/services/label/label.service';
import { UserService } from 'src/app/services/user/user.service';

@Component({
  selector: 'app-bank-details-modal',
  templateUrl: './bank-details-modal.page.html',
  styleUrls: ['./bank-details-modal.page.scss'],
})
export class BankDetailsModalPage implements OnInit {

  bankDetails:any = {
    name: '',
    accountNumber: '',
    phoneNumber: '',
    ifscCode: '',
    upiId: ''
  }

  userbankDetails: any;

  loading: any;
  SHARED_LABELS: any = {};
  constructor(private modalController: ModalController,private userService: UserService,
              private loadingController: LoadingController, private labelService: LabelService,) {
               }

  ngOnInit() {
  }

  ionViewWillEnter() {
    this.SHARED_LABELS = this.labelService.labels['SHARED'];
    console.log('userbankDetails',this.userbankDetails);
    if(this.userbankDetails.bankDetails){
      this.bankDetails.name = this.userbankDetails.bankDetails.name;
      this.bankDetails.accountNumber = this.userbankDetails.bankDetails.accountNumber;
      this.bankDetails.phoneNumber = this.userbankDetails.bankDetails.phoneNumber;
      this.bankDetails.ifscCode = this.userbankDetails.bankDetails.ifscCode;
      this.bankDetails.upiId = this.userbankDetails.bankDetails.upiId;
    }
  }

  async presentLoading() {
    this.loading = await this.loadingController.create({
      message: this.SHARED_LABELS['please_wait'],
    });
    await this.loading.present();
  }


  async closeModal() {
    this.modalController.dismiss();
  }

  async saveBankDetails(){
    console.log('bankDetails', this.bankDetails);
    this.presentLoading();
    await this.userService.saveBankDetails(this.bankDetails);
    this.loading.dismiss();
    this.modalController.dismiss('updated');
  }

}