import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { LoadingController, ModalController } from '@ionic/angular';
import { LabelService } from 'src/app/services/label/label.service';
import { UserService } from 'src/app/services/user/user.service';
export class BankDetailsModalPage {
    constructor(modalController, userService, loadingController, labelService) {
        this.modalController = modalController;
        this.userService = userService;
        this.loadingController = loadingController;
        this.labelService = labelService;
        this.bankDetails = {
            name: '',
            accountNumber: '',
            phoneNumber: '',
            ifscCode: '',
            upiId: ''
        };
        this.SHARED_LABELS = {};
    }
    ngOnInit() {
    }
    ionViewWillEnter() {
        this.SHARED_LABELS = this.labelService.labels['SHARED'];
        console.log('userbankDetails', this.userbankDetails);
        if (this.userbankDetails.bankDetails) {
            this.bankDetails.name = this.userbankDetails.bankDetails.name;
            this.bankDetails.accountNumber = this.userbankDetails.bankDetails.accountNumber;
            this.bankDetails.phoneNumber = this.userbankDetails.bankDetails.phoneNumber;
            this.bankDetails.ifscCode = this.userbankDetails.bankDetails.ifscCode;
            this.bankDetails.upiId = this.userbankDetails.bankDetails.upiId;
        }
    }
    presentLoading() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            this.loading = yield this.loadingController.create({
                message: this.SHARED_LABELS['please_wait'],
            });
            yield this.loading.present();
        });
    }
    closeModal() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            this.modalController.dismiss();
        });
    }
    saveBankDetails() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            console.log('bankDetails', this.bankDetails);
            this.presentLoading();
            yield this.userService.saveBankDetails(this.bankDetails);
            this.loading.dismiss();
            this.modalController.dismiss('updated');
        });
    }
}
