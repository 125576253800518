import { environment } from './../../../environments/environment';
import { Storage } from '@ionic/storage';
import { Injectable } from '@angular/core';
import { ModalController, AlertController, NavController, LoadingController, ToastController,Events } from '@ionic/angular';
import { SocialSharing } from '@ionic-native/social-sharing/ngx';
import { NavigationExtras } from '@angular/router';
//import { FirebaseDynamicLinks } from '@ionic-native/firebase-dynamic-links/ngx';
import { LabelService } from '../label/label.service';
import { LogglyLoggerService } from '../loggly-logger/loggly-logger.service';
import { AngularFirestore } from '@angular/fire/firestore';
import * as moment from 'moment';
import { ConfigService } from '../config/config.service';
//import { MultiRegionService } from '../multi-region/multi-region.service';
import * as firebase from 'firebase';
import { convertSnaps } from '../db-utils';
import { first, map } from 'rxjs/operators';
import { MultiRegionService } from '../multi-region/multi-region.service';
import { UtilsService } from '../utils/utils.service';
import { VendorService } from '../vendor/vendor.service';
import { HomePage } from 'src/app/home/home.page';
import { SharedNewService } from '../shared-new/shared-new.service';
import { MultiCountriesService } from '../multi-countries/multi-countries.service';

@Injectable({
    providedIn: 'root'
})
export class SharedService {
    referrer = { userId: '' };
    isPromoPopupViewed = false;
    sharedLinkRouted = false;
    loading: any;
    constructor(private modalController: ModalController,
        private storage: Storage,
        private alertController: AlertController,
        private socialSharing: SocialSharing,
        //private firebaseDynamicLinks: FirebaseDynamicLinks,
        private navCtrl: NavController,
        private labelService: LabelService,
        private logglyService: LogglyLoggerService,
        private afs: AngularFirestore,
        private configService: ConfigService,
        private events:Events,
        //private multiRegionService: MultiRegionService,
        private loadingController: LoadingController,
        private toastController: ToastController,
        private multiRegionService: MultiRegionService,
        private utilsService:UtilsService,
        private vendorService: VendorService,
        private multiCountriesService: MultiCountriesService,
        private sharedNewService: SharedNewService
        ) { }


   /* async openLoginModalForIdentity(uid: string, name: string, email: string, phoneNo: string) {
        try {
            const modal = await this.modalController.create({
                component: HomePage,
                cssClass: 'custom-modal login-modal',
                backdropDismiss: false,
                showBackdrop: true,
                componentProps: {
                    showIdentity: true,
                    showSignin: false,
                    showPincode: false,
                    showVerifyOtp: false,
                    showPhoneNo: false,
                    userId: uid,
                    btnTxt: 'Save',
                    userName: name,
                    userEmail: email,
                    phoneNo: phoneNo,
                    onlyIdentity: true
                }
            });
            await modal.present();
        } catch (error) {
            error['location'] = 'shared-service:openLoginModalForIdentity';
            this.logglyService.log(error);
        }

    }*/

    // async openLoginModalForIdentity(uid: string, name: string, email: string, phoneNo: string) {
    //     try {
    //         const modal = await this.modalController.create({
    //             component: HomePage,
    //             cssClass: 'custom-modal login-modal',
    //             backdropDismiss: false,
    //             showBackdrop: true,
    //             componentProps: {
    //                 showIdentity: true,
    //                 showSignin: false,
    //                 showPincode: false,
    //                 showVerifyOtp: false,
    //                 showPhoneNo: false,
    //                 userId: uid,
    //                 btnTxt: 'Save',
    //                 userName: name,
    //                 userEmail: email,
    //                 phoneNo: phoneNo,
    //                 onlyIdentity: true
    //             }
    //         });
    //         await modal.present();
    //     } catch (error) {
    //         error['location'] = 'shared-service:openLoginModalForIdentity';
    //         this.logglyService.log(error);
    //     }

    // }

    async checkLoginStatus(msg: string) {
        const uid = await this.getStorageUid();
        this.utilsService.consoleLog('check login uid', uid)
        if (!uid || uid === undefined) {
           this.utilsService.openLoginModal()
           //this.events.publish('app:openLoginModal');
        } else {
            const role = await this.getStorageRole();
            if (role === 'admin') {
                this.presentAlert(msg);
            } else if (role === 'deliveryAgent') {
                this.presentAlert(msg);
            } else {
                return true;
            }
        }
    }

    async socialShare(name: string, subject: string, img: string, link: string) {
        try {
            this.socialSharing.share(name, subject, img, link).then(() => {
                // console.log('app sharing works!');
            }).catch((err) => {
                // console.log('app sharing not works!', err);
            });
        } catch (error) {
            error['location'] = 'shared-service:socialShare';
            this.logglyService.log(error);
        }

    }

    handleProductSharing() {
       /* try {
            this.firebaseDynamicLinks.onDynamicLink()
                .subscribe((res: any) => {
                    if (res.deepLink.includes('product-details')) {
                        this.navigateToProduct(res.deepLink);
                    } else {
                        this.setReferralObj(res.deepLink);
                    }
                }), (error: any) => {
                    // console.log(error)
                    this.logglyService.log(error)
                };
        } catch (error) {
            error['location'] = 'shared-service:handleProductSharing';
            this.logglyService.log(error);
        }*/

    }

    navigateToProduct(link: string) {
        try {
            // console.log('link', link);
            let pid = '';
            for (let index = link.length - 2; index > 0; index--) {
                if (link[index] === '/') {
                    break;
                } else {
                    pid += link[index];
                }
            }
            pid = pid.split('').reverse().join('');
            // console.log('pid from dynamic link:', pid);
            this.sharedLinkRouted = true;
            const navigationExtras: NavigationExtras = {
                state: {
                    productId: pid
                }
            };
            this.navCtrl.navigateRoot(['product-details'], navigationExtras);
        } catch (error) {
            error['location'] = 'shared-service:navigateToProduct';
            this.logglyService.log(error);
        }

    }

    setReferralObj(link: string) {
        try {
            // console.log('link', link);
            let userId = '';
            for (let index = link.length - 2; index > 0; index--) {
                if (link[index] === '/') {
                    break;
                } else {
                    userId += link[index];
                }
            }
            userId = userId.split('').reverse().join('');
            // console.log('userId from dynamic link:', userId);
            this.referrer.userId = userId;
        } catch (error) {
            error['location'] = 'shared-service:setReferralObj';
            this.logglyService.log(error);
        }
    }

    async getStorageUid(): Promise<string> {
        try {
            return new Promise(async (resolve, reject) => {
                this.storage.get('uid').then((val: string) => {
                    resolve(val);
                });
            });
        } catch (error) {
            error['location'] = 'shared-service:getStorageUid';
            this.logglyService.log(error);
        }

    }

    async getStorageRole(): Promise<string> {
        try {
            return new Promise(async (resolve, reject) => {
                this.storage.get('userRole').then((val: string) => {
                    resolve(val);
                });
            });
        } catch (error) {
            error['location'] = 'shared-service:getStorageRole';
            this.logglyService.log(error);
        }
    }

    async getBannersImgPreloader(): Promise<string> {
        try {
            return new Promise(async (resolve, reject) => {
                this.storage.get('bannersImgPreloader').then((value) => {
                    resolve(value);
                });
            });
        } catch (error) {
            error['location'] = 'shared-service:getBannersImgPreloader';
            this.logglyService.log(error);
        }

    }

    async getCategoriesProductsImgsPreloader(): Promise<string> {
        try {
            return new Promise(async (resolve, reject) => {
                this.storage.get('categoriesProductsImgPreloader').then((value) => {
                    resolve(value);
                });
            });
        } catch (error) {
            error['location'] = 'shared-service:getCategoriesProductsImgsPreloader';
            this.logglyService.log(error);
        }

    }

    async getBrandsProductsImgsPreloader(): Promise<string> {
        try {
            return new Promise(async (resolve, reject) => {
                this.storage.get('brandsProductsImgPreloader').then((value) => {
                    resolve(value);
                });
            });
        } catch (error) {
            error['location'] = 'shared-service:getBrandsProductsImgsPreloader';
            this.logglyService.log(error);
        }

    }

    async getSubCategoriesImgsPreloader(): Promise<string> {
        try {
            return new Promise(async (resolve, reject) => {
                this.storage.get('subcategoriesImgPreloader').then((value) => {
                    resolve(value);
                });
            });
        } catch (error) {
            error['location'] = 'shared-service:getSubCategoriesImgsPreloader';
            this.logglyService.log(error);
        }

    }

    async getStorageDeliverySettings(): Promise<any> {
        try {
            return new Promise(async (resolve, reject) => {
                this.storage.get('deliverySettings').then((val: any) => {
                    resolve(val);
                });
            });
        } catch (error) {
            error['location'] = 'shared-service:getStorageDeliverySettings';
            this.logglyService.log(error);
        }

    }

    async getStorageCheckPincode(): Promise<any> {
        try {
            return new Promise(async (resolve, reject) => {
                this.storage.get('checkPincode').then((val: any) => {
                    resolve(val);
                });
            });
        } catch (error) {
            error['location'] = 'shared-service:getStorageCheckPincode';
            this.logglyService.log(error);
        }

    }
    async getStorageRegion(): Promise<any> {
        try {
            return new Promise(async (resolve, reject) => {
                this.storage.get('region').then((region: any) => {
                    resolve(region);
                });
            });
        } catch (error) {
            error['location'] = 'shared-service:getStorageRegionId';
            this.logglyService.log(error);
        }

    }


    async presentAlert(msg: string, btn = this.labelService.labels['SHARED']['ok'], backdropDismiss = true, page = '') {
        const alert = await this.alertController.create({
            message: msg,
            backdropDismiss,
            buttons: [{
                text: btn,
                handler: () => {
                    if(page) {
                        this.navCtrl.navigateRoot([page]);
                    } else {
                        return;
                    }
                }
            }]
        });
        await alert.present();
    }

    async presentLoading(duration = null, msg = this.labelService.labels['SHARED']['please_wait']) {
        this.loading = await this.loadingController.create({
            message: msg,
            duration: duration,
        });
        await this.loading.present();
    }

    async presentToast(msg) {
        const toast = await this.toastController.create({
            message: msg,
            duration: 2000
        });
        toast.present();
    }

    async checkLimitedTimeDeal(data): Promise<{ dealTime: any; discount: any; }> {
        if (data.hasOwnProperty('deal') && data.deal.isAllowed) {
            let discount = data.deal.discount;
            if('specificUsers' in data.deal && data.deal.specificUsers.active && data.deal.specificUsers.users && data.deal.specificUsers.users.length) {
                const uid = await this.getStorageUid();
                const index = data.deal.specificUsers.users.findIndex(u => u.id === uid);
                if(index === -1) {
                    return {dealTime: null, discount: null};
                } else {
                    discount = data.deal.specificUsers.users[index].discount;
                }
            }
            const currentTime = moment();
            const startDate = moment(data.deal.start.date).format('YYYY-MM-DD');
            const startTime = moment(data.deal.start.time).format('HH:mm');
            const endDate = moment(data.deal.end.date).format('YYYY-MM-DD');
            const endTime = moment(data.deal.end.time).format('HH:mm');
            const startDateTime = moment(`${startDate} ${startTime}`);
            const endDateTime = moment(`${endDate} ${endTime}`);

            if (moment(currentTime).isBetween(startDateTime, endDateTime)) {
                //console.log('in between');
                const diff = moment(endDateTime, 'YYYY-MM-DD HH:mm:ss').diff(moment(currentTime, 'DD/MM/YYYY HH:mm:ss'));
                const duration = moment.duration(diff);
                //console.log('duration', duration);
                //console.log('moment.utc(diff).format(":mm:ss")', moment.utc(diff).format(":mm:ss"));
                const dealTime = Math.floor(duration.asHours()) + moment.utc(diff).format(":mm:ss");
                //console.log('dealTime', dealTime);
                return {dealTime, discount};
            } else {
                //console.log('not between');
                return {dealTime: null, discount: null};
            }
        } else {
            return {dealTime: null, discount: null};
        }
    }

    getStarColor(rating) {
        if (rating >= 3) {
            return '#20c020';
        } else if (rating < 3 && rating >= 2) {
            return '#FF9F00';
        } else {
            return '#ff6161';
        }
    }

    async getUserCartProducts() {
        return new Promise(async (resolve, reject) => {
            try {
                const uid = await this.getStorageUid();
                if (uid && uid !== undefined) {
                    const cartRef = this.afs.collection('users').doc(uid).collection('cart');
                    const cartProducts = await cartRef.snapshotChanges().pipe(
                        map(snaps => convertSnaps(snaps))).pipe(first()).toPromise();
                    resolve(cartProducts);
                }
            } catch (err) {
                console.dir(err);
                err['location'] = 'shared-service:getUserCartProducts';
                this.logglyService.log(err);
            }
        });
    }

    async checkRegionIdForApi(): Promise<any> {
        try {
            return new Promise(async (resolve, reject) => {
                let region = {regionId: '', vendorId: ''};
                const role = await this.getStorageRole();
                if (role !== 'admin') {
                    let isMultiRegion = this.configService.environment.multiRegion;
                    let isMultiVendor = this.configService.environment.multiVendor;
                    if (isMultiRegion) {
                        const dbMultiRegion: any = await this.multiRegionService.getActiveStatus('service');
                        isMultiRegion = dbMultiRegion && dbMultiRegion.active;
                    }
                    if (isMultiRegion) {
                        const userRegion: any = await this.getStorageRegion();
                        if (userRegion) {
                            region.regionId = userRegion.id;
                            // if(userRegion.vendorId) {
                            //     const vendorSettings: any = await this.vendorService.getActiveStatus('service');
                            //     if(isMultiVendor && vendorSettings && vendorSettings.active && vendorSettings.oneVendorPerRegion) {
                            //         const vendor: any = await this.vendorService.getVendorData(userRegion.vendorId);
                            //         region.vendorId = vendor.active ? userRegion.vendorId : 'inactive';
                            //     }
                            // }
                        }
                    }
                }
                resolve(region);
            });
        } catch (error) {
            error['location'] = 'shared-service:checkRegionIdForApi';
            this.logglyService.log(error);
        }
    }

    async manageExclusiveCategories(isCategoryExclusive: boolean, categoryId: string, categoryType: string) {
        let exclusiveObj = await this.storage.get('exclusiveObj');
        if(!exclusiveObj || exclusiveObj === undefined) {
            exclusiveObj = {exclusivity: isCategoryExclusive || false, category: isCategoryExclusive || false, categoryId, categoryType};
        } else {
            exclusiveObj['categoryId'] = categoryId;
            exclusiveObj['categoryType'] = categoryType;
            if(isCategoryExclusive) {
                exclusiveObj['category'] = true;
            } else {
                exclusiveObj['category'] = false;
            }
        }
       // console.log('exclusiveObj in shared', exclusiveObj);
        this.storage.set('exclusiveObj', exclusiveObj);
    }

      async prepareProductData(data: any) {
        const wishlistProducts = data.wishlistProducts,
            subRole = data.subRole,
            userId = data.userId,
            cartProducts = data.cartProducts;

        let product = data.product;
            //console.log('data product', product);
        const cartIndex = cartProducts.findIndex(c => c.productId === product.id);
        if (userId !== '' && (cartIndex !== -1)) {
            product['cartQty'] = cartProducts[cartIndex].quantity;
            product['cartId'] = cartProducts[cartIndex].id;
        } else {
            product['cartQty'] = 0
            product['cartId'] = '';
        }
        if (userId !== '' && wishlistProducts && wishlistProducts.length && wishlistProducts.indexOf(product.id) !== -1) {
            product['isWishlisted'] = true;
        } else {
            product['isWishlisted'] = false;
        }
        product = await this.updateProductPrices(product, subRole);
    return product;
  }

  async updateProductPrices(product, subRole) {

    let deal = await this.checkLimitedTimeDeal(product);
    if (deal.dealTime) {
        if (deal.discount > 0) {
            product.dealActive = true;
            if (!product.isPriceList) {
                product.discountedPrice = product.prodPrice - (product.prodPrice * (deal.discount / 100));
            } else {
                product.priceList.forEach(pl => {
                    pl.discountedPrice = pl.price - (pl.price * (deal.discount / 100));
                });
            }
        }
    }
    if (subRole && subRole === 'retailer' && this.configService.environment.priceForRetail) {
        let retailDiscount = product.retailDiscount ? product.retailDiscount : 0;
        let retailDiscountType = product.retailDiscountType || 'percentage';
        if (retailDiscount) {
            if (!product.isPriceList) {
                if(retailDiscountType === 'percentage') {
                    product.discountedPrice = product.prodPrice - (product.prodPrice * (retailDiscount / 100))
                } else {
                    product.discountedPrice = product.prodPrice - retailDiscount;
                }
            } else {
                product.priceList.forEach(pl => {
                    if(retailDiscountType === 'percentage') {
                        pl.discountedPrice = pl.price - (pl.price * (retailDiscount / 100))
                    } else {
                        pl.discountedPrice = pl.price - retailDiscount;
                    }
                });
            }
        }
    }
    if (!product.isPriceList) {
        product.discountedPrice = this.getPriceBasedOnExhangeRate(product.discountedPrice);
        product.prodPrice = this.getPriceBasedOnExhangeRate(product.prodPrice);
    } else {
        product.priceList.forEach(pl => {
            pl.discountedPrice = this.getPriceBasedOnExhangeRate(pl.discountedPrice);
            pl.price = this.getPriceBasedOnExhangeRate(pl.price);
        });
    }
    return product;
  }

  getPriceBasedOnExhangeRate(price) {
      return this.sharedNewService.getPriceBasedOnExhangeRate(price);
    }

    async openLoginModal() {
        const modal = await this.modalController.create({
        component: HomePage,
        backdropDismiss:false,
        cssClass: "custom-modal login-modal",
        });

        if(this.configService.environment.appAccessOnApproval) {
            modal.onDidDismiss().then(async (data) => {
                await this.presentLoading();
                setTimeout(async () => {
                    this.loading.dismiss();
                    await this.checkForAppAccesss();
                }, 2000);
            })
        }
        await modal.present();
    }

    async checkForAppAccesss() {
        const uid = await this.getStorageUid();
        if (!uid || uid === undefined) {
            this.loginForAppAccessAlert();
        } else {
            const accessByAdmin = await this.storage.get('accessByAdmin');
           // console.log('accessByAdmin', accessByAdmin);
            if(!accessByAdmin) {
                this.accessNotAllowedAlert();
            }
        }
      }

      async loginForAppAccessAlert() {
        const alert = await this.alertController.create({
            message: this.labelService.labels['SHARED']['login_to_continue'],
            backdropDismiss: false,
            buttons: [{
                text: this.labelService.labels['SHARED']['login'],
                handler: () => {
                    this.openLoginModal();
                }
            }]
        });

        await alert.present();
    }
      async accessNotAllowedAlert() {
        const alert = await this.alertController.create({
            message: this.labelService.labels['SHARED']['access_not_allowed_msg'],
            backdropDismiss: false
        });

        await alert.present();
    }

    async getFaqData() {
        return new Promise(async (resolve, reject) => {
            // let data = JSON.parse(sessionStorage.getItem("faqPageData")) || {};
            let data = JSON.parse(localStorage.getItem("faqPageData") || " {}");
            if (!(Object.keys(data).length)) {
              data = await this.afs.collection('settings').doc('contactUs').valueChanges().pipe(first()).toPromise();
                sessionStorage.setItem("faqPageData", JSON.stringify(data));
            }
            resolve(data);
        });
    }

    async presentAlertConfirm(msg: string) {
        return new Promise(async (resolve) => {
          const alert = await this.alertController.create({
            message: msg,
            buttons: [
              {
                text: this.labelService.labels['SHARED']['no'],
                role: 'cancel',
                cssClass: 'secondary',
                handler: () => {
                  //console.log('Confirm Cancel: blah');
                  resolve(false);
                }
              }, {
                text: this.labelService.labels['SHARED']['yes'],
                handler: () => {
                  //console.log('Confirm Okay');
                  resolve(true);
                }
              }
            ]
          });

          await alert.present();
        });
    }

    dismissLoading() {
    	this.loading.dismiss();
	}

	convert24to12(time) {
    	return moment(time, ['HH:mm']).format('hh:mm A');
	}
}
